import Vue from 'vue'
import Router from 'vue-router'
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router)

/* Layout */
import Layout from '../views/layout/Layout'

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/

//所有权限通用路由表
//如首页和登录页和一些不用权限的公用页面
export const constantRouterMap = [
  { path: '/login', component: () => import('@/views/login/index'), hidden: true },
  { path: '/error/401', component: () => import('@/views/error/401'), hidden: true },
  { path: '/error/404', component: () => import('@/views/error/404'), hidden: true },

  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    name: 'Dashboard',
    hidden: true,
    children: [{
      path: 'dashboard',
      component: () => import('@/views/dashboard/index')
    }]
  },
  {
    path: '',
    component: Layout,
    name: 'Info',
    hidden: true,
    children: [{
      path: '/info',
      component: () => import('@/views/info/index')
    }]
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/redirect/index')
      }
    ],
  }
]


//实例化vue的时候只挂载constantRouter
export default new Router({
  mode: 'history',
  routes: constantRouterMap
});


//异步挂载的路由
//动态需要根据权限加载的路由表
//meta无permissions字段则不做权限控制
export const asyncRouterMap = [

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'User',
    meta: { title: '用户管理', permissions: ['user'], icon: 'users' },
    children: [
      {
        path: '/user/list',
        name: 'Users',
        component: () => import('@/views/user/index'),
        meta: { title: '用户列表', permissions: ['user'] }
      },
      {
        path: '/user/distribution',
        name: 'UserDistribution',
        hidden: true,
        component: () => import('@/views/user/distribution'),
        meta: { title: '用户分销', permissions: ['user'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Finance',
    meta: { title: '经营数据', permissions: ['finance'], icon: 'finance' },
    children: [
      {
        path: '/finance/day',
        name: 'FinanceDay',
        component: () => import('@/views/finance/day'),
        meta: { title: '营业日结', permissions: ['finance'] }
      },
      {
        path: '/finance/bill',
        name: 'FinanceBill',
        component: () => import('@/views/finance/bill'),
        meta: { title: '订单流水', permissions: ['finance'] }
      },
      {
        path: '/finance/report',
        name: 'FinanceReport',
        component: () => import('@/views/finance/report'),
        meta: { title: '财务报表', permissions: ['finance'] }
      },
      {
        path: '/finance/dataAnalysis',
        name: 'DataAnalysis',
        component: () => import('@/views/finance/dataAnalysis'),
        meta: { title: '数据分析', permissions: ['finance'] }
      },
      {
        path: '/finance/comprehensiveAnalysis',
        name: 'ComprehensiveAnalysis',
        component: () => import('@/views/finance/comprehensiveAnalysis'),
        meta: { title: '综合分析', permissions: ['finance'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Order',
    meta: { title: '订单管理', permissions: ['order'], icon: 'order' },
    children: [
      {
        path: '/order/list',
        name: 'OrderList',
        component: () => import('@/views/order/index'),
        meta: { title: '订单列表', permissions: ['order'] }
      },
      {
        path: '/order/compensate',
        name: 'compensate',
        component: () => import('@/views/order/compensate'),
        meta: { title: '赔付列表', permissions: ['order'] }
      },
      {
        path: '/finance/day2',
        name: 'FinanceDay2',
        component: () => import('@/views/finance/day'),
        meta: { title: '营业日结', permissions: ['finance'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Comment',
    meta: { title: '评价管理', permissions: ['comment'], icon: 'comment' },
    children: [
      {
        path: '/comment/list',
        name: 'CommentList',
        component: () => import('@/views/comment/index'),
        meta: { title: '评价列表', permissions: ['comment'] }
      },
      {
        path: '/comment/appeal',
        name: 'CommentAppeal',
        component: () => import('@/views/comment/appeal'),
        meta: { title: '申诉列表', permissions: ['comment'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'School',
    meta: { title: '学校管理', permissions: ['school'], icon: 'shop' },
    children: [
      {
        path: '/building/index',
        name: 'BuildingList',
        component: () => import('@/views/building/index'),
        meta: { title: '楼栋列表' }
      },
      {
        path: '/building/floor',
        name: 'BuildingFloor',
        hidden: true,
        component: () => import('@/views/building/floor'),
        meta: { title: '楼层列表' }
      },
      {
        path: '/school/index',
        name: 'SchoolList',
        component: () => import('@/views/school/index'),
        meta: { title: '学校列表' }
      },
      {
        path: '/school/canteen',
        name: 'SchoolCanteen',
        component: () => import('@/views/school/canteen'),
        meta: { title: '食堂列表' }
      },
      {
        path: '/shop/shopCategory',
        name: 'ShopCategory',
        component: () => import('@/views/shop/shopCategory'),
        meta: { title: '店铺分类' }
      },
      {
        path: '/shop/index',
        name: 'ShopList',
        component: () => import('@/views/shop/index'),
        meta: { title: '店铺列表' }
      },
      {
        path: '/school/ledgerRole',
        name: 'ledgerRole',
        component: () => import('@/views/school/ledgerRole'),
        meta: { title: '分账角色列表' }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Withdraw',
    meta: { title: '提现管理', permissions: ['withdraw'], icon: 'withdraw' },
    children: [
      // {
      //   path: '/finance/withdraw',
      //   name: 'WithdrawApply',
      //   component: () => import('@/views/finance/withdraw'),
      //   meta: { title: '提现申请' }
      // },
      {
        path: '/shop/withdraw',
        name: 'ShopWithdraw',
        component: () => import('@/views/shop/withdraw'),
        meta: { title: '店铺提现' }
      },
      {
        path: '/distributeman/withdraw',
        name: 'DistributemanWithdraw',
        component: () => import('@/views/distributeman/withdraw'),
        meta: { title: '分餐员提现' }
      },
      {
        path: '/sendman/withdraw',
        name: 'SendmanWithdraw',
        component: () => import('@/views/sendman/withdraw'),
        meta: { title: '配送员提现' }
      },
      {
        path: '/user/withdraw',
        name: 'UserWithdraw',
        component: () => import('@/views/user/withdraw'),
        meta: { title: '用户提现' }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'SP',
    meta: { title: '分账管理', permissions: ['sp'], icon: 'shop' },
    children: [
      {
        path: '/school/ledgerRolePhone',
        name: 'ledgerRolePhone',
        hidden: true,
        component: () => import('@/views/school/ledgerRolePhone'),
        meta: { title: '绑定的手机号列表' }
      },
      {
        path: '/school/spOrder',
        name: 'spOrder',
        component: () => import('@/views/school/spOrder'),
        meta: { title: '分账订单' }
      },
      {
        path: '/school/revenueLog',
        name: 'revenueLog',
        component: () => import('@/views/school/revenueLog'),
        meta: { title: '分账收入记录', permissions: ['school'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Shop',
    meta: { title: '商家管理', permissions: ['shop'], icon: 'goods' },
    children: [
      {
        path: '/shop/shopManager',
        name: 'ShopManager',
        hidden: true,
        component: () => import('@/views/shop/shopManager'),
        meta: { title: '店铺管理员' }
      },
      {
        path: '/shop/category',
        name: 'GoodsCategory',
        component: () => import('@/views/shop/category'),
        meta: { title: '分类列表', permissions: ['shop'] }
      },
      {
        path: '/shop/goods',
        name: 'GoodsList',
        component: () => import('@/views/shop/goods'),
        meta: { title: '商品列表', permissions: ['shop'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Coupon',
    meta: { title: '活动管理', permissions: ['coupon'], icon: 'coupon' },
    children: [
      {
        path: '/coupon/index',
        name: 'Coupon',
        component: () => import('@/views/coupon/index'),
        meta: { title: '优惠券列表' }
      },
      // {
      //   path: '/shop/activity',
      //   name: 'ActivityList',
      //   component: () => import('@/views/shop/shopActivity'),
      //   meta: { title: '商品活动' }
      // },
    ]
  },

  // {
  //   path: '',
  //   component: Layout,
  //   alwaysShow: true,
  //   name: 'Building',
  //   meta: { title: '楼栋管理', permissions: ['building'], icon: 'building' },
  //   children: [
  //     {
  //       path: '/building/index',
  //       name: 'BuildingList',
  //       component: () => import('@/views/building/index'),
  //       meta: { title: '楼栋列表', permissions: ['building'] }
  //     },
  //     {
  //       path: '/building/floor',
  //       name: 'BuildingFloor',
  //       hidden: true,
  //       component: () => import('@/views/building/floor'),
  //       meta: { title: '楼层列表' }
  //     },
  //   ]
  // },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Sendman',
    meta: { title: '配送员管理', permissions: ['sendman'], icon: 'sendman' },
    children: [
      {
        path: '/sendman/index',
        name: 'SendmanList',
        component: () => import('@/views/sendman/index'),
        meta: { title: '配送员列表', permissions: ['sendman'] }
      },
      {
        path: '/sendman/setting',
        name: 'SendmanSetting',
        component: () => import('@/views/sendman/setting'),
        meta: { title: '配送设置', permissions: ['sendman'] }
      },
      {
        path: '/sendman/settle',
        name: 'SendmanSettle',
        hidden: true,
        component: () => import('@/views/sendman/settle'),
        meta: { title: '结算' }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Device',
    meta: { title: '餐柜管理', permissions: ['device'], icon: 'cupboard' },
    children: [
      {
        path: '/device/index',
        name: 'DeviceIndex',
        component: () => import('@/views/device/index'),
        meta: { title: '设备列表', permissions: ['device'] }
      },
      {
        path: '/device/box',
        name: 'DeviceBox',
        component: () => import('@/views/device/box'),
        meta: { title: '箱子列表', permissions: ['device'] }
      },
      {
        path: '/device/order',
        name: 'DeviceOrder',
        component: () => import('@/views/device/order'),
        meta: { title: '投餐订单', permissions: ['device'] }
      },
      {
        path: '/device/use',
        name: 'DeviceUse',
        component: () => import('@/views/device/use'),
        meta: { title: '投餐统计', permissions: ['device'] }
      },
      {
        path: '/device/useDetail',
        name: 'DeviceUseDetail',
        hidden: true,
        component: () => import('@/views/device/useDetail'),
        meta: { title: '投餐统计详情' }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Member',
    meta: { title: '会员管理', permissions: ['member'], icon: 'member' },
    children: [
      {
        path: '/member/level',
        name: 'MemberLevel',
        component: () => import('@/views/member/level'),
        meta: { title: '等级设置', permissions: ['member'] }
      },
      {
        path: '/member/order',
        name: 'MemberOrder',
        component: () => import('@/views/member/order'),
        meta: { title: '购买订单', permissions: ['member'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Wallet',
    meta: { title: '钱包管理', permissions: ['wallet'], icon: 'wallet' },
    children: [
      {
        path: '/wallet/charge',
        name: 'WalletCharge',
        component: () => import('@/views/wallet/charge'),
        meta: { title: '充值设置', permissions: ['wallet'] }
      },
      {
        path: '/wallet/order',
        name: 'WalletOrder',
        component: () => import('@/views/wallet/order'),
        meta: { title: '充值订单', permissions: ['wallet'] }
      },
      {
        path: '/wallet/rechargeRecovery',
        name: 'WalletRechargeRecovery',
        component: () => import('@/views/wallet/rechargeRecovery'),
        meta: { title: '充值回收', permissions: ['wallet'] }
      },
      {
        path: '/wallet/shopWallet',
        name: 'ShopWallet',
        component: () => import('@/views/wallet/shopWallet'),
        meta: { title: '店铺钱包', permissions: ['wallet'] }
      },
      {
        path: "/wallet/shopWalletLog",
        name: "ShopWalletLog",
        hidden: true,
        component: () => import("@/views/wallet/shopWalletLog"),
        meta: { title: "店铺钱包明细" }
      },
      {
        path: '/wallet/distributemanWallet',
        name: 'DistributemanWallet',
        component: () => import('@/views/wallet/distributemanWallet'),
        meta: { title: '分拣员钱包', permissions: ['wallet'] }
      },
      {
        path: "/wallet/distributemanWalletLog",
        name: "DistributemanWalletLog",
        hidden: true,
        component: () => import("@/views/wallet/distributemanWalletLog"),
        meta: { title: "分拣员钱包明细" }
      },
      {
        path: '/wallet/sendmanWallet',
        name: 'SendmanWallet',
        component: () => import('@/views/wallet/sendmanWallet'),
        meta: { title: '配送员钱包', permissions: ['wallet'] }
      },
      {
        path: "/wallet/sendmanWalletLog",
        name: "SendmanWalletLog",
        hidden: true,
        component: () => import("@/views/wallet/sendmanWalletLog"),
        meta: { title: "配送员钱包明细" }
      },
      {
        path: '/wallet/userWallet',
        name: 'UserWallet',
        component: () => import('@/views/wallet/userWallet'),
        meta: { title: '用户钱包', permissions: ['wallet'] }
      },
      {
        path: "/wallet/userWalletLog",
        name: "UserWalletLog",
        hidden: true,
        component: () => import("@/views/wallet/userWalletLog"),
        meta: { title: "用户钱包明细" }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Advert',
    meta: { title: '广告管理', permissions: ['advert'], icon: 'advert' },
    children: [
      {
        path: '/advert/index',
        name: 'AdvertIndex',
        component: () => import('@/views/advert/index'),
        meta: { title: '小程序广告', permissions: ['advert'] }
      },
      {
        path: '/advert/deviceAd',
        name: 'DeviceAd',
        component: () => import('@/views/advert/deviceAd'),
        meta: { title: '餐柜广告', permissions: ['advert'] }
      },
      {
        path: '/advert/banner',
        name: 'banner',
        component: () => import('@/views/advert/banner'),
        meta: { title: '轮播图设置', permissions: ['advert'] }
      },
      {
        path: '/advert/bestSelling',
        name: 'BestSelling',
        component: () => import('@/views/advert/bestSelling'),
        meta: { title: '精选好店', permissions: ['advert'] }
      },
      {
        path: '/advert/homeAdvert',
        name: 'HomeAdvert',
        component: () => import('@/views/advert/homeAdvert'),
        meta: { title: '首页广告', permissions: ['advert'] }
      },
      {
        path: '/advert/goodsFlashSale',
        name: 'GoodsFlashSale',
        component: () => import('@/views/advert/goodsFlashSale'),
        meta: { title: '商品限时抢购', permissions: ['advert'] }
      },
      {
        path: '/advert/selectActivitiesGoods',
        name: 'SelectActivitiesGoods',
        hidden: true,
        component: () => import('@/views/advert/selectActivitiesGoods'),
        meta: { title: '选择活动商品' }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Site',
    meta: { title: '建站管理', permissions: ['site'], icon: 'site' },
    children: [
      {
        path: '/site/join',
        name: 'join',
        component: () => import('@/views/site/join'),
        meta: { title: '加盟申请', permissions: ['site'] }
      },
      {
        path: '/site/feedback',
        name: 'feedback',
        component: () => import('@/views/site/feedback'),
        meta: { title: '意见反馈', permissions: ['site'] }
      },
      {
        path: '/site/company',
        name: 'company',
        component: () => import('@/views/site/company'),
        meta: { title: '公司介绍', permissions: ['site'] }
      },
      {
        path: '/site/agreement',
        name: 'agreement',
        component: () => import('@/views/site/agreement'),
        meta: { title: '服务协议', permissions: ['site'] }
      },
      {
        path: '/site/agreementDetail',
        name: 'agreementDetail',
        hidden: true,
        component: () => import('@/views/site/agreementDetail'),
        meta: { title: '服务协议详情' }
      },
      {
        path: '/site/systemConfig',
        name: 'systemConfig',
        component: () => import('@/views/site/systemConfig'),
        meta: { title: '系统配置', permissions: ['site'] }
      },
    ]
  },

  // {
  //   path: '',
  //   component: Layout,
  //   alwaysShow: true,
  //   name: 'Data',
  //   meta: { title: '数据管理', permissions: ['banner','about'], icon: 'data' },
  //   children: []
  // },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Saleman',
    meta: { title: '业务员管理', permissions: ['saleman'], icon: 'staff' },
    children: [
      {
        path: '/saleman/index',
        name: 'Saleman',
        component: () => import('@/views/saleman/index'),
        meta: { title: '业务员列表', permissions: ['saleman'] }
      },
      {
        path: '/saleman/income',
        name: 'SalemanIncome',
        hidden: true,
        component: () => import('@/views/saleman/income'),
        meta: { title: '提成' }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Distributeman',
    meta: { title: '分餐员管理', permissions: ['distributeman'], icon: 'distribute' },
    children: [
      {
        path: '/distributeman/index',
        name: 'DistributemanIndex',
        component: () => import('@/views/distributeman/index'),
        meta: { title: '分餐员列表', permissions: ['distributeman'] }
      },
      {
        path: '/distributeman/order',
        name: 'DistributemanOrder',
        component: () => import('@/views/distributeman/order'),
        meta: { title: '分餐订单', permissions: ['distributeman'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'attendance',
    meta: { title: '考勤打卡', permissions: ['attendance'], icon: 'attendance' },
    children: [
      {
        path: '/attendance/config',
        name: 'attendanceConfig',
        component: () => import('@/views/attendance/config'),
        meta: { title: '考勤配置' }
      },
      {
        path: '/attendance/codeList',
        name: 'attendanceCodeList',
        component: () => import('@/views/attendance/codeList'),
        meta: { title: '考勤码列表' }
      },
      {
        path: '/attendance/distributeman',
        name: 'attendanceDistributeman',
        component: () => import('@/views/attendance/distributeman'),
        meta: { title: '分拣员考勤' }
      },
      {
        path: '/attendance/sendman',
        name: 'attendanceSendman',
        component: () => import('@/views/attendance/sendman'),
        meta: { title: '配送员考勤' }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'System',
    meta: { title: '权限管理', permissions: ['admin'], icon: 'system' },
    children: [
      {
        path: '/admin/list',
        name: 'Admin',
        component: () => import('@/views/admin/index'),
        meta: { title: '账号列表', permissions: ['admin'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Log',
    meta: { title: '日志管理', permissions: ['log'], icon: 'log' },
    children: [
      {
        path: '/log/list',
        name: 'Admin',
        component: () => import('@/views/log/index'),
        meta: { title: '日志列表', permissions: ['log'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'CCB',
    meta: { title: '龙存管', permissions: ['ccb'], icon: 'CCB' },
    children: [
      // {
      //   path: '/ccb/list',
      //   name: 'CCB',
      //   component: () => import('@/views/ccb/index'),
      //   meta: { title: '登记簿', permissions: ['ccb'] }
      // },
      {
        path: '/ccb/userAccount',
        name: 'userAccount',
        component: () => import('@/views/ccb/userAccount'),
        meta: { title: '学校账户', permissions: ['ccb'] }
      },
      {
        path: '/ccb/platAccount',
        name: 'platAccount',
        component: () => import('@/views/ccb/platAccount'),
        meta: { title: '平台账户', permissions: ['ccb'] }
      },
    ]
  },

  // {
  //   path: '',
  //   component: Layout,
  //   alwaysShow: true,
  //   name: 'Content',
  //   meta: { title: '内容管理', permissions: ['banner','about'], icon: 'content' },
  //   children: [
  //     {
  //       path: '/banner/list',
  //       name: 'Banners',
  //       component: () => import('@/views/banner/index'),
  //       meta: { title: '轮播图设置', permissions: ['banner'] }
  //     },
  //     {
  //       path: '/about',
  //       name: 'About',
  //       component: () => import('@/views/about/index'),
  //       meta: { title: '关于我们', permissions: ['about'] }
  //     }
  //   ]
  // },

  // {
  //   path: '',
  //   component: Layout,
  //   alwaysShow: true,
  //   name: 'Others',
  //   meta: { title: '其他', icon: 'others' },
  //   children: [
  //     {
  //       path: '/map',
  //       name: 'Map',
  //       component: () => import('@/views/others/map'),
  //       meta: { title: '地图' }
  //     }
  //   ]
  // },
  { path: '*', redirect: '/error/404', hidden: true },
]
