<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select class="filter-item" filterable v-model="listQuery.shop_id" placeholder="所属店铺" style="width: 140px" clearable>
        <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
        </el-option>
      </el-select>
      <el-button type="primary" class="filter-item" size="small" @click="handleFilter">搜索</el-button>
    </div>

    <el-table
            v-loading="listLoading"
            :data="list"
            size="small"
            element-loading-text="Loading"
            border
            fit
            highlight-current-row>
      <el-table-column label="ID" width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

<!--      <el-table-column label="学校" min-width="110" align="center">-->
<!--        <template slot-scope="scope">-->
<!--          {{ scope.row.school.school_name }}-->
<!--        </template>-->
<!--      </el-table-column>-->

      <el-table-column label="店铺" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.shop.shop_name }}
        </template>
      </el-table-column>

      <el-table-column label="商品名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.goods.goods_name }}
        </template>
      </el-table-column>

      <el-table-column label="商品封面" min-width="110" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.goods.goods_thumb" class="user-avatar">
        </template>
      </el-table-column>

      <el-table-column label="商品原价" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.goods.sell_price }}
        </template>
      </el-table-column>

      <el-table-column label="商品现价" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.act_price }}
        </template>
      </el-table-column>

      <el-table-column label="面向用户" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.user_type === 0">不限制</span>
          <span v-else-if="scope.row.user_type === 1">门店新客</span>
        </template>
      </el-table-column>

      <el-table-column label="每单限购" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.order_limit === -1">不限购</span>
          <span v-else>{{ scope.row.order_limit }}</span>
        </template>
      </el-table-column>

      <el-table-column label="当前库存" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.stock === -1">无限份</span>
          <span v-else>{{ scope.row.stock }}</span>
        </template>
      </el-table-column>

      <el-table-column label="活动开始时间" min-width="150" align="center">
        <template slot-scope="scope">
          {{ scope.row.activities.start_time }}
        </template>
      </el-table-column>

      <el-table-column label="活动结束时间" min-width="150" align="center">
        <template slot-scope="scope">
          {{ scope.row.activities.end_time }}
        </template>
      </el-table-column>

      <el-table-column label="活动生效周期" min-width="160" align="center">
        <template slot-scope="scope">
          <el-tag v-for="(item, index) in scope.row.activities.week" :key="index">
            <span v-if="item === 1">星期一</span>
            <span v-else-if="item === 2">星期二</span>
            <span v-else-if="item === 3">星期三</span>
            <span v-else-if="item === 4">星期四</span>
            <span v-else-if="item === 5">星期五</span>
            <span v-else-if="item === 6">星期六</span>
            <span v-else-if="item === 7">星期日</span>
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="活动生效时间段" min-width="160" align="center">
        <template slot-scope="scope">
          <el-tag v-for="(item, index) in scope.row.activities.period" :key="index">
            {{ item[0] }} ~ {{ item[1] }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="活动状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.activities.status == 0">待生效</el-tag>
          <el-tag type="success" v-else-if="scope.row.activities.status == 1">进行中</el-tag>
          <el-tag type="danger" v-else-if="scope.row.activities.status == 2">已结束</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="200" class-name="small-padding fixed-width" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleCreate(scope.row)">设为限时抢购</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
  import request from '@/utils/request'
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        list: null,
        total: 0,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          school_id: '',
          shop_id: '',
        },
        form: {
          id: '',
          activities_goods_id: '',
          weight: 0,
        },
        // 店铺
        shops: [],
      }
    },
    created() {
      this.getList()
      this.getShopList()
    },
    computed: {
      ...mapGetters(["schools", "school_id"])
    },
    watch: {
      school_id(newVal, oldVal) {
        this.listQuery.school_id = newVal;
        this.getList();
        this.getShopList();
      },
    },
    methods: {
      getList() {
        this.listQuery.school_id = this.school_id;
        this.listLoading = true
        request({
          url: '/api/backend/goodsFlashSale/selectActivitiesGoods',
          method: 'get',
          params: this.listQuery,
        }).then(response => {
          this.list = response.data.data
          this.total = response.data.total
          this.listLoading = false
        })
      },
      getShopList() {
        request({
          url: "/api/backend/shop/list",
          method: "get",
          params: {
            page: 1,
            limit: 1000000,
            school_id: this.school_id,
          }
        }).then(response => {
          this.shops = response.data.data;
        });
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val
        this.getList()
      },
      handleCreate(row) {
        if (!this.school_id) {
          this.$message({
            type: "warning",
            message: "请选择学校"
          });
          return;
        } else {
          this.form.school_id = this.school_id;
        }
        this.form.activities_goods_id = row.id;

        request({
          url: '/api/backend/goodsFlashSale/save',
          method: 'post',
          data: this.form
        }).then(() => {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          this.getList();
        }).catch((err) => {
          console.log(err)
        })
      },
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 6px;
  }
</style>
