<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button type="primary" class="filter-item" size="small" @click="handleCreate">新增</el-button>

      <el-select v-model="listQuery.is_on" placeholder="状态" style="width: 120px" class="filter-item" size="small" clearable>
        <el-option label="下架" :value="0"/>
        <el-option label="正常" :value="1"/>
      </el-select>
      <el-button type="primary" class="filter-item" size="small" @click="handleFilter">搜索</el-button>
    </div>

    <el-table
            v-loading="listLoading"
            :data="list"
            size="small"
            element-loading-text="Loading"
            border
            fit
            highlight-current-row>
      <el-table-column label="ID" width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school.school_name }}
        </template>
      </el-table-column>

      <el-table-column label="广告图" min-width="110" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.img" class="advert-img">
        </template>
      </el-table-column>

      <el-table-column label="跳转类型" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.link_type == 0">不跳转</span>
          <span v-else-if="scope.row.link_type == 1">领券中心</span>
          <span v-else-if="scope.row.link_type == 2">店铺</span>
          <span v-else-if="scope.row.link_type == 3">商品</span>
          <span v-else-if="scope.row.link_type == 4">外部链接</span>
        </template>
      </el-table-column>

      <el-table-column label="跳转链接" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.link }}
        </template>
      </el-table-column>

      <el-table-column label="权重" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.weight }}
        </template>
      </el-table-column>

      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on === 1" type="success">正常</el-tag>
          <el-tag v-else-if="scope.row.is_on === 0" type="danger">下架</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="250" class-name="small-padding fixed-width" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)">修改</el-button>

          <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 详情 -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-width="180px">
        <el-form-item label="广告图" prop="img">
          <upload-one v-model="form.img"></upload-one>
          <div style="font-size:12px;color:red;">
            *推荐尺寸：340*240
          </div>
        </el-form-item>

        <el-form-item label="跳转类型" prop="link_type">
          <el-select v-model="form.link_type">
            <el-option label="不跳转" :value="0"></el-option>
            <el-option label="领券中心" :value="1"></el-option>
            <el-option label="店铺" :value="2"></el-option>
            <el-option label="商品" :value="3"></el-option>
            <el-option label="外部链接" :value="4"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="链接" prop="link">
          <el-input v-model="form.link" />
          <div style="line-height:16px;font-size: 12px;color:red;margin-top:10px;">
            <div>*店铺页面，此处填店铺id</div>
            <div>*商品页面，此处填商品id，只支持商城的商品ID</div>
            <div>*外部链接，此处填链接url，需在微信小程序管理平台添加该业务域名</div>
          </div>
        </el-form-item>

        <el-form-item label="权重" prop="weight">
          <el-input type="number" v-model="form.weight" />
          <div style="line-height:16px;font-size: 12px;color:red;margin-top:10px;">
            <div>*权重越大越前排</div>
          </div>
        </el-form-item>

        <el-form-item label="是否上架" prop="is_on">
          <el-switch v-model="form.is_on" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->
  </div>
</template>

<script>
  import request from '@/utils/request'
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        list: null,
        total: 0,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          school_id: '',
          is_on: '',
        },
        dialogFormVisible: false,
        dialogStatus: '',
        textMap: {
          create: '新增',
          update: '修改',
        },
        form: {
          id: '',
          img: '',
          link_type: 0,
          link: '',
          weight: 0,
          is_on: 1,
        },
        rules: {
          img: [{ required: true, message: '广告图不能为空', trigger: 'change' }],
          link_type: [{ required: true, message: '跳转类型不能为空', trigger: 'change' }],
          weight: [{ required: true, message: '权重不能为空', trigger: 'change' }],
          is_on: [{ required: true, message: '状态不能为空', trigger: 'change' }],
        },
        btnLoading: false,
      }
    },
    created() {
      this.getList()
    },
    computed: {
      ...mapGetters(["schools", "school_id"])
    },
    watch: {
      school_id(newVal, oldVal) {
        this.listQuery.school_id = newVal;
        this.getList();
      },
    },
    methods: {
      getList() {
        this.listQuery.school_id = this.school_id;
        this.listLoading = true
        request({
          url: '/api/backend/homeAdvert/list',
          method: 'get',
          params: this.listQuery,
        }).then(response => {
          this.list = response.data.data
          this.total = response.data.total
          this.listLoading = false
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val
        this.getList()
      },
      resetForm() {
        this.form = {
          id: '',
          img: '',
          link_type: 0,
          link: '',
          weight: 0,
          is_on: 1,
        }
        this.btnLoading = false
      },
      handleCreate() {
        this.resetForm()
        this.dialogStatus = 'create'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['form'].clearValidate() //清除校验结果
        })
      },
      handleUpdate(row) {
        this.resetForm()
        this.form = Object.assign({}, row)
        this.dialogStatus = 'update'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['form'].clearValidate() //清除校验结果
        })
      },
      saveData() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            if (!this.school_id) {
              this.$message({
                type: "warning",
                message: "请选择学校"
              });
              return;
            } else {
              this.form.school_id = this.school_id;
            }

            this.btnLoading = true
            request({
              url: '/api/backend/homeAdvert/save',
              method: 'post',
              data: this.form
            }).then(() => {
              this.dialogFormVisible = false
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.getList();
            }).catch((err) => {
              console.log(err)
            }).finally(() => {
              this.btnLoading = false
            })
          }
        })
      },
      handleDelete(row) {
        this.$confirm('确定继续该操作', '提示', {
          type: 'warning'
        }).then(() => {
          request({
            url: '/api/backend/homeAdvert/delete',
            method: 'post',
            data: {id: row.id},
          }).then(() => {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getList()
          })
        }).catch((err) => {
          console.log(err)
        });
      },
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .advert-img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 6px;
  }
</style>
