<template>
  <el-menu class="navbar" mode="horizontal">
    <hamburger :toggle-click="toggleSideBar" :is-active="sidebar.opened" class="hamburger-container" />
    <breadcrumb />
    <div class="notices" v-if="user.user.type === 'admin'">
      <el-popover class="icon" placement="top" width="100" trigger="click" ref="popover">
        <div class="handle">
          <el-button style="border: none;padding: 9px 15px;text-align: right;" @click="handleUpdateAllIsRead()" :disabled="no_read_count===0">全部已读</el-button>

            <div style="max-height: 300px;overflow: auto;">
              <div class="item" v-for="(item, index) in list" :key="index">
                <router-link v-if="item.type === 1" to="/site/join">
                  <el-button style="border: none; width: 100%; margin: 0;text-align: left;" size="small" @click="handleUpdateIsRead(item.id)">您有一条加盟申请信息<span v-if="!item.is_read" class="item-dot"></span></el-button>
                </router-link>

                <router-link v-else-if="item.type === 2" to="/site/feedback">
                  <el-button style="border: none; width: 100%; margin: 0;text-align: left;" size="small" @click="handleUpdateIsRead(item.id)">您有一条意见反馈信息<span v-if="!item.is_read" class="item-dot"></span></el-button>
                </router-link>
              </div>
            </div>
        </div>

        <div slot="reference" class="icon-box">
          <el-badge v-if="no_read_count > 0" :value="no_read_count" :max="99">
            <i class="el-icon-bell"></i>
          </el-badge>
          <i v-else class="el-icon-bell"></i>
        </div>
      </el-popover>
    </div>
    <div class="school-selecter">
      <el-select size="small" v-model="school_id" placeholder="请选择学校">
        <el-option v-for="item in schools" :key="item.id" :label="item.school_name" :value="item.id">
        </el-option>
      </el-select>
    </div>
    <el-dropdown class="avatar-container" trigger="click">
      <div class="avatar-wrapper">
        <!-- <img :src="avatar+'?imageView2/1/w/80/h/80'" class="user-avatar"> -->
        <!-- <img src="@/assets/avatar.jpeg" class="user-avatar"> -->
        <img v-if="logoURL" :src="logoURL" class="user-avatar" />
        <i class="el-icon-caret-bottom" />
      </div>
      <el-dropdown-menu slot="dropdown" class="user-dropdown">
        <el-dropdown-item disabled>
          {{ username }}
        </el-dropdown-item>
        <router-link class="inlineBlock" to="/info">
          <el-dropdown-item divided>
            修改密码
          </el-dropdown-item>
        </router-link>
        <el-dropdown-item>
          <span style="display:block;" @click="logout">退出登录</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </el-menu>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
import request from "@/utils/request";

export default {
  components: {
    Breadcrumb,
    Hamburger
  },
  computed: {
    ...mapGetters(["sidebar", "avatar", "username", "schools", "user"]),
    school_id: {
      get() {
        return this.$store.state.user.school_id;
      },
      set(newVal) {
        this.$store.dispatch("SetSchoolId", newVal);
        this.listQuery.school_id = newVal;
        this.getList();
      }
    },
    logoURL() {
      // 变量直接 return '@/assets/dyd_logo.jpg' 静态资源不会被 webpack 打包
      // 所以要require进来才能正常显示
      return require(process.env.VUE_APP_LOGO)
      // return require('@/assets/dyd_logo.jpg')
    },
  },
  data() {
    return {
      list: null,
      listQuery: {
        school_id: ""
      },
      no_read_count: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("ToggleSideBar");
    },
    logout() {
      this.$store.dispatch("FedLogOut").then(() => {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    },
    getList() {
      this.listQuery.school_id = this.school_id;
      request({
        url: "/api/backend/notices/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.list;
        this.no_read_count = response.data.no_read_count;
      });
    },
    handleUpdateIsRead(val) {
      this.$refs[`popover`].doClose()

      request({
        url: "/api/backend/notices/updateIsRead",
        method: "post",
        data: { id: val }
      }).then(response => {
        this.getList();
      });
    },
    handleUpdateAllIsRead() {
      this.$refs[`popover`].doClose()

      request({
        url: "/api/backend/notices/updateAllIsRead",
        method: "post",
        data: this.listQuery
      }).then(response => {
        this.getList();
      });
    }
  }
};
</script>

<style lang="scss">
.school-selecter {
  top: 5px;
  position: absolute;
  right: 110px;
  display: inline-block;
  align-items: center;
  .el-select {
    background: #edf7fe;
    border-left: 5px solid #63bafa;
    border-radius: 5px;
    overflow: hidden;
    .el-input {
      height: 40px;
      line-height: 40px;
    }
    input {
      border: 0;
      background: transparent;
    }
  }
}
.notices{
  display: inline-block;
  position: absolute;
  right: 340px;
  *{box-sizing: border-box;}
}
.icon{
  height: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.icon-box{
  font-size: 25px;
  color: #606266;
  line-height: 25px;
}
.el-popover{
  width: auto !important;
  padding: 12px 0;
}
.handle .el-scrollbar__wrap{
  overflow-x: hidden;
}
.handle{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-end;
}
.item-dot{
  margin-left: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  background:#F56C6C;
  border-radius: 50%;
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .screenfull {
    position: absolute;
    right: 90px;
    top: 16px;
    color: red;
  }
  .avatar-container {
    height: 50px;
    display: inline-block;
    position: absolute;
    right: 35px;
    .avatar-wrapper {
      cursor: pointer;
      margin-top: 5px;
      position: relative;
      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 10px;
      }
      .el-icon-caret-bottom {
        position: absolute;
        right: -20px;
        top: 25px;
        font-size: 12px;
      }
    }
  }
  // .school-selecter {
  //   height: 50px;
  //   position: absolute;
  //   right: 125px;
  // }
}
</style>
