<template>
    <div class="app-container">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>充值回收</span>
            </div>
            <el-form ref="form" label-width="160px" size="small" style="width:60%">
                <el-form-item label="钱包">
                    <el-radio-group v-model="form.wallet_type" @change="radioChange">
                        <el-radio :label="1" border>店铺钱包</el-radio>
                        <el-radio :label="2" border>分拣员钱包</el-radio>
                        <el-radio :label="3" border>配送员钱包</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="目标">
                    <el-select v-model="form.target_id" placeholder="请输入店铺名称" filterable remote :remote-method="userFilter"  :loading="userFilterLoading" style="width:340px" clearable v-if="form.wallet_type === 1">
                        <el-option v-for="item in targetUsers" :key="item.id" :label="item.shop_name" :value="item.id">
                            <div style="display: flex;align-items:center;text-align:center;">
                                <img :src="item.thumb" style="width: 26px;height:26px;border-radius:4px;margin-right:4px;">
                                <span>{{ item.shop_name }}</span>
                            </div>
                        </el-option>
                    </el-select>

                    <el-select v-model="form.target_id" placeholder="请输入姓名/手机号" filterable remote :remote-method="userFilter"  :loading="userFilterLoading" style="width:340px" clearable v-else-if="form.wallet_type === 2">
                        <el-option v-for="item in targetUsers" :key="item.id" :label="item.user ? item.user.name : ''" :value="item.id">
                            <div style="display: flex;align-items:center;text-align:center;">
                                <img :src="item.user ? item.user.avatar : ''" style="width: 26px;height:26px;border-radius:4px;margin-right:4px;">
                                <span>{{ item.user ? item.user.name : '' }} / {{ item.user ? item.user.phone : '' }}</span>
                            </div>
                        </el-option>
                    </el-select>

                    <el-select v-model="form.target_id" placeholder="请输入姓名/手机号" filterable remote :remote-method="userFilter"  :loading="userFilterLoading" style="width:340px" clearable v-else-if="form.wallet_type === 3">
                        <el-option v-for="item in targetUsers" :key="item.id" :label="item.name" :value="item.id">
                            <div style="display: flex;align-items:center;text-align:center;">
                                <img :src="item.avatar" style="width: 26px;height:26px;border-radius:4px;margin-right:4px;">
                                <span>{{ item.name }} / {{item.phone}}</span>
                            </div>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="操作">
                    <el-radio v-model="form.type" :label="1" border>充值</el-radio>
                    <el-radio v-model="form.type" :label="2" border>回收</el-radio>
                </el-form-item>
                <el-form-item label="金额">
                    <el-input v-model="form.amount" type="number"/>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="form.remark" type="textarea"/>
                </el-form-item>
                <el-form-item>
                    <el-button @click="resetForm" size="small">重置</el-button>
                    <el-button type="primary" @click="saveData" :loading="btnLoading" size="small">提交</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                btnLoading: false,
                form: {
                    wallet_type: 1,
                    target_id: '',
                    type: 1,
                    amount: '',
                    remark: '',
                },
                // 目标
                targetUsers: [],
                userFilterLoading: false,
            };
        },
        created() {

        },
        computed: {
            ...mapGetters(["schools", "school_id","user"])
        },
        watch: {
            school_id(newVal, oldVal) {
                this.form.target_id = '';
                this.targetUsers = [];
            },
        },
        methods: {
            radioChange(val) {
                this.form.target_id = '';
                this.targetUsers = [];
            },
            // 目标
            userFilter(val) {
                this.userFilterLoading = true
                request({
                    url: "/api/backend/wallet/targetUser",
                    method: "get",
                    params: {
                        page: 1,
                        limit: 10,
                        wallet_type: this.form.wallet_type,
                        school_id: this.school_id,
                        keyword: val,
                    }
                }).then(response => {
                    this.targetUsers = response.data.data;
                }).finally(()=>{
                    this.userFilterLoading = false
                })
            },
            resetForm() {
                this.form = {
                    wallet_type: 1,
                    target_id: '',
                    type: 1,
                    amount: '',
                    remark: '',
                }
                this.targetUsers = [];
            },
            saveData() {
                if (this.form.target_id === '' || this.form.target_id < 0) {
                    this.$message({
                        type: "warning",
                        message: "请选择目标",
                    });
                    return;
                }
                if (this.form.amount === '' || this.form.amount <= 0) {
                    this.$message({
                        type: "warning",
                        message: "请输入正确金额",
                    });
                    return;
                }

                this.btnLoading = true;
                request({
                    url: "/api/backend/wallet/rechargeRecovery",
                    method: "post",
                    data: this.form
                }).then(response => {
                    this.$message({
                        type: "success",
                        message: "操作成功!"
                    });
                    this.resetForm()
                }).finally(() => {
                    this.btnLoading = false
                })
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss">
    .custom-inline-form {
        margin-bottom: 10px;
        .el-row {
            padding: 4px 0;
            font-weight: bold;
        }
        .el-form-item {
            margin-bottom: 4px !important;
        }
        .el-form-item__label {
            line-height: 20px !important;
        }
        .el-form-item__content {
            line-height: 20px !important;
        }
    }
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
