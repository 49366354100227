<template>
    <div class="app-container">
        <div class="filter-container">
            <el-button type="primary" class="filter-item" size="small" @click="$router.go(-1)">返回</el-button>

            <el-input v-model="listQuery.keyword" placeholder="请输入用户昵称/姓名/手机号/专属号/openid" style="width: 350px;" class="filter-item" size="small" clearable/>

            <el-date-picker v-model="dateArr" type="datetimerange" align="right" value-format="yyyy-MM-dd HH:mm:ss" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :default-time="['00:00:00', '23:59:59']" class="filter-item" size="small" style="display: inline-flex;" clearable></el-date-picker>

            <el-button type="primary" class="filter-item" size="small" @click="handleFilter">搜索</el-button>
        </div>

        <el-tabs v-model="tableType" type="border-card" @tab-click="handleTableType" class="filter-container">
            <el-tab-pane :label="tableTypeName[0]" :name="tableTypeName[0]">
                <!--一代用户-->
                <el-table
                        v-loading="listLoading"
                        :data="list1"
                        size="small"
                        element-loading-text="Loading"
                        border
                        fit
                        highlight-current-row>
                    <el-table-column align="center" label="ID" width="60">
                        <template slot-scope="scope">
                            <!-- {{ scope.$index }} -->
                            {{ scope.row.id }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="上级ID" width="80">
                        <template slot-scope="scope">
                            {{ scope.row.parent_id }}
                        </template>
                    </el-table-column>
                    <el-table-column label="openid" min-width="110" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.openid }}
                        </template>
                    </el-table-column>
                    <el-table-column label="姓名" min-width="110" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="手机号" min-width="110" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.phone }}
                        </template>
                    </el-table-column>
                    <el-table-column label="专属号" min-width="110" align="center">
                        <template slot-scope="scope">
                            <el-tag>{{ scope.row.u_no }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="微信" min-width="110" align="center">
                        <template slot-scope="scope">
                            <div style="display: flex;flex-direction:column;align-items:center;text-align:center;">
                                <img :src="scope.row.avatar" class="user-avatar">
                                <span>{{ scope.row.nickname }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="性别" min-width="80" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.gender == 0">未设置</span>
                            <span v-else-if="scope.row.gender == 1">男</span>
                            <span v-else-if="scope.row.gender == 2">女</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="余额" min-width="100" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.balance }}
                        </template>
                    </el-table-column>
                    <el-table-column label="积分" min-width="100" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.integral }}
                        </template>
                    </el-table-column>
                    <el-table-column label="积分等级" min-width="100" align="center">
                        <template slot-scope="scope">
                            Lv.{{ scope.row.member_level }} {{scope.row.level.name}}
                        </template>
                    </el-table-column>
                    <el-table-column label="已购等级" min-width="80" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.purchase_level ? scope.row.purchase_level.name : '无' }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="created_at" label="注册时间" min-width="200">
                        <template slot-scope="scope">
                            <i class="el-icon-time" />
                            <span>{{ scope.row.created_at }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>

            <el-tab-pane :label="tableTypeName[1]" :name="tableTypeName[1]">
                <!--推荐收益-->
                <el-table
                        v-loading="listLoading"
                        :data="list2"
                        size="small"
                        element-loading-text="Loading"
                        border
                        fit
                        highlight-current-row>
                    <el-table-column label="学校" min-width="110" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.school?scope.row.school.school_name:'' }}
                        </template>
                    </el-table-column>

                    <el-table-column label="买家" min-width="120" align="center">
                        <template slot-scope="scope">
                            <div style="display: flex;flex-direction:column;align-items:center;text-align:center;">
                                <img :src="scope.row.buyer.avatar" class="user-avatar" />
                                <span style="width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ scope.row.buyer.nickname }}</span>
                                <span style="width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ scope.row.buyer.name }}</span>
                                <span style="width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ scope.row.buyer.phone }}</span>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="订单号" min-width="110" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.order_no }}
                        </template>
                    </el-table-column>

                    <el-table-column label="奖金" min-width="110" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.bonus }}
                        </template>
                    </el-table-column>

                    <el-table-column label="创建时间" min-width="160" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.created_at }}
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                dateArr: null,
                tableType: "一代用户",
                tableTypeName: ["一代用户", "推荐收益"],
                list1: null,
                list2: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: "",
                    user_id: "",
                    parent_id: "",
                    keyword: "",
                    start_time: "",
                    end_time: "",
                },
            };
        },
        created() {
            this.listQuery.user_id = this.listQuery.parent_id = parseInt(this.$route.query.user_id||0)
            this.getTableTypeList()
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"])
        },
        watch: {
            // school_id(newVal, oldVal) {
            //     this.listQuery.school_id = newVal;
            //     this.getList();
            //     this.distributionUserList();
            // },
            dateArr(newVal, oldVal) {
                if (newVal) {
                    this.listQuery.start_time = newVal[0];
                    this.listQuery.end_time = newVal[1];
                } else {
                    this.listQuery.start_time = "";
                    this.listQuery.end_time = "";
                }
            },
        },
        methods: {
            // 分销用户列表
            distributionUserList() {
                // this.listQuery.school_id = this.school_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/user/list",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list1 = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            // 推荐收益
            recommendRewardList() {
                // this.listQuery.school_id = this.school_id;
                this.listLoading = true
                request({
                    url: "/api/backend/user/recommendRewardList",
                    method: "get",
                    params: this.listQuery,
                }).then(response => {
                    this.list2 = response.data.data
                    this.total = response.data.total
                    this.listLoading = false
                })
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getTableTypeList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getTableTypeList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getTableTypeList();
            },
            // 切换表格类型数据
            handleTableType() {
                this.listQuery.page = 1
                this.getTableTypeList()
            },
            // 获取表格类型数据
            getTableTypeList() {
                this.list1 = this.list2 = null
                let tableType = this.tableType
                if (tableType === this.tableTypeName[0]) {
                    this.distributionUserList()
                } else if (tableType === this.tableTypeName[1]) {
                    this.recommendRewardList()
                }
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss">
    .custom-inline-form {
        margin-bottom: 10px;
        .el-row {
            padding: 4px 0;
            font-weight: bold;
        }
        .el-form-item {
            margin-bottom: 4px !important;
        }
        .el-form-item__label {
            line-height: 20px !important;
        }
        .el-form-item__content {
            line-height: 20px !important;
        }
    }
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
